import './App.css';
import { Inicio } from './inicio/inicio';
import { Raiz } from './inicio/raiz';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.css";
import { Inventario } from './inventario/inventario';
import { ActivoRegistro } from './activoregistro/activoregistro';
import { ActivoVer } from './activover/activover';
import logohg from './images/logohg.png';


function App() {
  let fecha = new Date();
  let anio = fecha.getFullYear();
  return (
    <Router>
    <nav className="py-2 bg-light border-bottom">
    <div className="container d-flex flex-wrap">
    <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
          <img src={logohg} className="bi me-2"  height="40" role="img"></img>
    </a>  
      
    </div>
  </nav>
      
      <Routes>
       
        <Route path="/activo" element={<Inicio/>} />
        <Route path="/activo/inventario" element={<Inventario/>} />
        <Route path="/activo/registro" element={<ActivoRegistro/>} />
        <Route path="/activo/ver" element={<ActivoVer/>} />
      </Routes>

      <footer className='bg-dark text-center text-white' >
        <div className="text-center">
          <a target='_blank' href="http://transporteshagemsa.com">HAGEMSA</a> © 2008- {anio}  Todos los derechos reservados.<br/>Version 1.0
        </div>
      </footer>
    </Router>
  );
}

export default App;
